import React from "react";
import { Stack, Box } from "@mui/material";
import PolicyLinks from "../../components/common/PolicyLinks";
import BrandTitle from "../../components/common/BrandTitle";
import GoogleSignInButton from "../../components/auth/GoogleSignInButton";

function StartMenu() {
  return (
    <Box
      data-testid="start-menu-layout"
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      sx={{
        backgroundColor: {
          xs: "#fcdbb9",
          md: "inherit",
        },
      }}>
      <Stack spacing={6} alignItems="center">
        <BrandTitle />
        <GoogleSignInButton>Sign In with Google</GoogleSignInButton>
        <PolicyLinks />
      </Stack>
    </Box>
  );
}

export default StartMenu;
