import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: ["-apple-system", "BlinkMacSystemFont", '"Segoe UI"', "Roboto", '"Helvetica Neue"', "Arial", "sans-serif", '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(","),
    brand: {
      fontFamily: "Zeyada, Arial, sans-serif",
      fontWeight: 400,
      fontSize: 88,
      textAlign: "center",
    },
    h1: {
      fontWeight: 600,
      fontSize: 36,
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#2E2E30",
    },
    secondary: {
      main: "#BCBCBE",
    },
    error: {
      main: "#E57373",
    },
    warning: {
      main: "#CFA616",
    },
    success: {
      main: "#00C6C2",
    },
    info: {
      main: "#6389FF",
    },
    board: {
      main: "#f2f2f4",
    },
    menu: {
      main: "#ecebef",
    },
    paper: {
      main: "#F8F8FA",
    },

    text: {
      primary: "#2E2E30",
      secondary: "#595959",
      light: "#C1C1C8",
      dark: "#939395",
      disabled: "#A2A2A4",
    },
    button: {
      easy: "#FBFBFD",
      moderate: "#C2C2C4",
      hard: "#A7A7A9",
      veryHard: "#5F5F61",
    },
  },

  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(255, 255, 255, 0.2)",
          backdropFilter: "blur(7px)",
          WebkitBackdropFilter: "blur(7px)",
          boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
          border: "1px solid rgba(255, 255, 255, 0.3)",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          // #E6E6FA #B3B3C6 #828395
          background: "linear-gradient(to right bottom, #ffebcd, #ffe8c9, #ffe5c5, #ffe1c2, #ffdebe, #fcdbb9, #f8d7b5, #f5d4b0, #eed0a9, #e6cca2, #dec89c, #d6c496);",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: 24,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "none",
        },
      },
      variants: [
        {
          props: { variant: "board" },
          style: {
            // #F8F9FF #F5F9FF #98ACCF
            boxShadow: "0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)",
          },
        },
      ],
    },
    MuiListItemButton: {
      variants: [
        {
          props: { variant: "menu" },
          style: {
            paddingRight: "0",
            paddingLeft: "0",
          },
        },
      ],
    },
    MuiListItemText: {
      variants: [
        {
          props: { variant: "menu" },
          style: {
            marginLeft: "25px",
          },
        },
      ],
    },
  },
});

export default theme;
