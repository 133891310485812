import React from "react";
import { Box, Paper, Stack } from "@mui/material";
import MainMenu from "../menus/MainMenu";
import SyncButton from "../common/sync/SyncButton";

const Board = ({ children }) => {
  return (
    <Paper
      data-testid="board-layout"
      variant="board"
      sx={{
        position: "relative",
        width: {
          xs: "100%",
          sm: "90%",
          md: "90%",
          lg: "90%",
          xl: "70%",
        },
        minHeight: {
          xs: "100vh",
          sm: 530,
        },
        backgroundColor: "board.main",
        mt: {
          xs: "20px !important",
        },
        margin: {
          sm: "20px !important",
        },
        borderRadius: "0px",
      }}>
      <Stack
        direction="row"
        sx={{
          alignItems: "stretch",
          height: "100%",
        }}>
        <Stack
          sx={{
            width: "100%",
            height: "100%",
          }}>
          <Stack
            direction="column"
            sx={{
              width: "100%",
              height: "100%",
            }}>
            {children}
            <Box
              sx={{
                display: { xs: "flex", sm: "none" },
                justifyContent: "center",
                alignItems: "center",
                mt: 1,
                mb: 4,
              }}>
              <SyncButton data-testid="board-sync-button" listItemStyle={{ width: "fit-content", display: "flex", justifyContent: "center" }} buttonStyle={{ borderRadius: "8px" }} />
            </Box>
          </Stack>
        </Stack>
        <Box
          component="nav"
          sx={{
            width: "auto",
            bgcolor: "menu.main",
            display: {
              xs: "none",
              sm: "block",
            },
          }}>
          <MainMenu />
        </Box>
      </Stack>
    </Paper>
  );
};

export default Board;
