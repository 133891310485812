import db from "../../database/db";
import { v4 as uuidv4 } from "uuid";

export const createDeck = async (deck, userId) => {
  const now = new Date().toISOString();
  deck.id = uuidv4();
  deck.createdBy = userId;
  deck.createdOn = now;
  deck.modifiedOn = deck.modifiedOn || now;
  deck.reviewedOn = deck.reviewedOn || now;
  deck.reviewAt = deck.reviewAt || now;
  deck.deletedOn = deck.deletedOn || null;
  deck.syncedOn = deck.syncedOn || null;
  deck.name = deck.name || "New Deck";
  deck.description = deck.description || "";
  deck.isDeleted = deck.isDeleted || false;
  deck.cards = deck.cards || [];
  return await db.decks.add(deck);
};

export const findAllDecksByUserId = async (userId, includeDeleted = false) => {
  if (!userId) return [];

  try {
    const filterCondition = includeDeleted
      ? (deck) => deck.createdBy === userId && deck.isDeleted === false && deck.deletedOn !== null
      : (deck) => deck.createdBy === userId && deck.isDeleted === false && (deck.deletedOn === undefined || deck.deletedOn === null);

    const decks = await db.decks.filter(filterCondition).toArray();

    // Sort decks by modifiedOn date in descending order
    decks.sort((a, b) => new Date(b.modifiedOn) - new Date(a.modifiedOn));

    return decks;
  } catch (error) {
    console.error("Error loading decks from IndexedDB:", error);
    throw error;
  }
};

export const findDeckById = async (deckId) => {
  if (!deckId) return null;

  try {
    const deck = await db.decks.get({ id: deckId });
    return deck ? deck : null;
  } catch (error) {
    console.error("Error fetching deck from IndexedDB:", error);
    throw error;
  }
};

export const updateDeck = async (deckId, updates) => {
  if (!deckId || !updates) return null;

  try {
    const deck = await db.decks.get({ id: deckId });
    if (!deck) {
      console.error("Deck not found:", deckId);
      return null;
    }

    const updatedFields = {
      ...updates,
      modifiedOn: new Date(),
    };

    await db.decks.update(deckId, updatedFields);
    return await db.decks.get({ id: deckId });
  } catch (error) {
    console.error("Error updating deck in IndexedDB:", error);
    throw error;
  }
};
